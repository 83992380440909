import React, { useEffect } from 'react'
import "../PaymentRecurring/PaymentRecurring.css"
import Banner from '../../Componants/Banner/Banner';
import Instantrefund from "../../assets/instant-refund.webp";
import "../InstantRefund/InstantRefund.css";
import bannerimg from "../../assets/instant-banner.jpg";

function InstantRefund({setBanner}) {
    const bannar = {
        banner: bannerimg,
        h1: "Improve Customer Retention With ",
        h2: "Instant Refunds",
        p: "Delight customers by providing refunds within 5 minutes of the refund request",
        height:"auto"
    };

    useEffect(() =>{
        setBanner(bannar)
    },[])
    return (
        <div>
        
            <div className='main'>
                <div className='payment-recurring'>
                    <div className='wrapper'>
                        <div className='payment-recurring-main'>
                            <div className='payment-recurring-left center'>
                            <div className='wrapper'>
                                    <h1 className='f-color f-40'>Why Enable Streamwellso Instant Refunds ?</h1>
                                    <div className='payment-recurring-content'>
                                        <ul className='l-height f-color fw-600 f-21'>
                                            <li>Round the clock refunds</li>
                                            <li>Real time analytics</li>
                                            <li>Offer refunds back to source</li>
                                            <li>Full control over refunds management</li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                            <div className='payment-recurring-right w-50'>
                            <img src={Instantrefund}></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='payment-steps'>
                    <div className='wrapper'>
                        <div className='payment-step-heading f-color f-32 fw-600'>
                            <h2>Activate Streamwellso Instant
<br />Refunds In 3 Simple Steps </h2>
                        </div>
                        <div className='payment-steps-icon gap-50'>
                            <div className='payment-steps-icon-column'>
                                <div className='payment-steps-wrapper payment-steps-wrapper-populated'>
                                    <div className='payment-steps-icon-label'>
                                        <h1 className='f-color fw-600 f-32'>STEP1</h1>
                                    </div>
                                    <div className='payment-steps-icon-container'>
                                        <div className="image-circle">
                                            <i class="fa-solid fa-right-to-bracket icon-size icon-color"></i>
                                        </div>
                                        <div className="image-circle-text">
                                            <h3 className='f-25'>Sign Up On Streamwellso</h3>
                                            <p> Complete your profile and link your bank account to start collecting payments</p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className='payment-steps-icon-column'>
                                <div className='payment-steps-wrapper payment-steps-wrapper-populated'>
                                    <div className='payment-steps-icon-label'>
                                        <h1 className='f-color fw-600 f-25'>STEP2</h1>
                                    </div>
                                    <div className='payment-steps-icon-container'>
                                        <div className="image-circle">
                                            <i class="fa-sharp fa-solid fa-share-nodes icon-size icon-color"></i>
                                        </div>
                                        <div className="image-circle-text">
                                            <h3 className='f-25'>Enable Streamwellso Payment Gateway</h3>
                                            <p> Integrate your business with Streamwellso payment gateway and start collecting payments</p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className='payment-steps-icon-column'>
                                <div className='payment-steps-wrapper payment-steps-wrapper-populated'>
                                    <div className='payment-steps-icon-label'>
                                        <h1 className='f-color fw-600 f-32'>STEP3</h1>
                                    </div>
                                    <div className='payment-steps-icon-container'>
                                        <div className="image-circle">

                                            <i class="fa-solid fa-envelopes-bulk icon-size icon-color"></i>


                                        </div>
                                        <div className="image-circle-text">
                                            <h3 className='f-25'>Request For Streamwellso Instant Refund</h3>
                                            <p>Email us at support@Streamwellso.com or contact your Account Manager</p>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default InstantRefund
