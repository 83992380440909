import React, { useEffect } from "react";
import "./Home.css";
import Banner from "../../Componants/Banner/Banner";
import content from "../../assets/contentImage.png";
import girl from "../../assets/girl.png";
import f1 from "../../assets/display.png";
import f2 from "../../assets/link.png";
import f3 from "../../assets/file.png";
import f4 from "../../assets/payroll.png";
import f5 from "../../assets/animate5.gif";
import p1 from "../../assets/1.png";
import p2 from "../../assets/2.png";
import p3 from "../../assets/3.png";
import p4 from "../../assets/4.png";
import { Link } from "react-router-dom";
import homeBanner from "../../assets/banner.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = ({ setBanner = () => {} }) => {
  const bannar = {
    banner: homeBanner,
    h1: "Make Payments Easier",
    h2: "with Online Payments",
    button: "Get Started!",
    buttonLink: "/contact",
    p: "Accept online payments and grow your business.",
    height: "auto",
  };

  useEffect(() => {
    setBanner(bannar);
    AOS.init();
  }, []);
  return (
    <div>
      {/* <Banner banner={bannar} /> */}
      <div className="main">
        {/* <div className="wrapper">
          <div className="business-growth text-center" >
            <h2  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">Unlock Access To Limitless Business Growth</h2>
            <h5  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
              We’re more than a payments partner. Get smoother payment processes
              and offer an outstanding experience.
            </h5>
            <div className="card-box d-flex justify-between gap-20" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
              <div className="card">
                <div className="icon">
                  <i class="fa-solid fa-money-bill"></i>
                </div>
                <h3 className="contant">Manage Your Money, Your Way</h3>
              </div>
              <div className="card">
                <div className="icon">
                <i class="fa-brands fa-instalod"></i>
                </div>
                <h3 className="contant">Insights To Power Your Business</h3>
              </div>
              <div className="card">
                <div className="icon">
                <i class="fa-solid fa-suitcase"></i>
                </div>
                <h3 className="contant">
                  Easy Integration To Go Live In Minutes
                </h3>
              </div>
              <div className="card">
                <div className="icon">
                <i class="fa-solid fa-trophy"></i>
                </div>
                <h3 className="contant">Total Flexibility In Payment Modes</h3>
              </div>
            </div>
          </div>
        </div> */}
        <div className="secontBox center">
          <div className="box d-flex gap-20">
            <div className="left">
              <h2>Best payment gateway built for India</h2>
              <p>
                We’re more than a payments partner. Get smoother payment
                processes and offer an outstanding experience.
              </p>
            </div>
            <div className="right">
              <div className="cardView center gap-10">
                <div className="img center">
                  <img src={p1} alt="" />
                </div>
                <div className="desc">
                  <h4>Insights To Power Your Business</h4>
                  <p>
                    Get payment products that suit your business needs. Manage
                    your money securely & efficiently.
                  </p>
                </div>
              </div>
              <div className="cardView center gap-10">
                <div className="img center">
                  <img src={p2} alt="" />
                </div>
                <div className="desc">
                  <h4>Easy Integration To Go Live In Minutes</h4>
                  <p>
                  Increase sales by offering 150+ payment options such as Credit & Debit cards, Netbanking, UPI, Wallets etc.
                  </p>
                </div>
              </div>
              <div className="cardView center gap-10">
                <div className="img center">
                  <img src={p3} alt="" />
                </div>
                <div className="desc">
                  <h4>Total Flexibility In Payment Modes</h4>
                  <p>
                  Take your business to new heights with India’s most trusted payments platform and experience unmatched success rates.               
                  </p>
                   </div>
                  
              </div>
              <div className="cardView center gap-10">
                <div className="img center">
                  <img src={p4} alt="" />
                </div>
                <div className="desc">
                  <h4>Accept payments and get fast settlements even on bank holiday.</h4>
                  <p>
                  Accept payments and get fast settlements even on bank holiday.                  </p>
                   </div>
                  
              </div>
            </div>
          </div>
        </div>
        <div className="finetech-wrapper">
          <div className="wrapper center gap-50">
            <div
              className="left"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img className="w-100" src={content} alt="" />
            </div>
            <div
              className="right"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <h3 className="h3">Our Vision</h3>
              <p className="gray" style={{ width: "348px" }}>
              Grow your business with End to end payment, one platform -Unlimited solutions. A customized, fully integrated & payment solutions provider will help your business to grow by collecting payments from customers easily and securely through any device in any mode. Enables businesses to collect online & offline payments across 150+ payment modes such as Credit Cards, Debit Cards, Net Banking, EMIs, BNPL, QR, UPI, Wallets and more.
              </p>


              <div className="button">
                <Link to="/about" className="btn btn-1">
                  View More About Us!
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="featured-services-wrapper services-wrapper text-center ">
          <h2 className="font-20">Our Featured Services</h2>
          <h3 className="h3">
            We Provide All Exclusive <br />
            Services For Clients
          </h3>
          <div className=" featured-services d-flex justify-center gap-50">
            <div className="featurer  ">
              <div className="img">
                <img src={f1} alt="featurer-1" />
              </div>
              <h3>Plug-and-play APIs</h3>
              <p className="grey">
                We offer easy to integrate and developer-friendly plug-and-play
                APIs that comes with detailed API docs
              </p>

              <Link to="/recurring-payments">
                Discover More <i class="fa-solid fa-arrow-right"></i>
              </Link>
            </div>
            <div className="featurer ">
              <div className="img">
                <img src={f2} alt="featurer-2" />
              </div>
              <h3>Payment Links</h3>
              <p className="grey">
                Instantly accept payments via links without the need of having a
                website or an app
              </p>

              <Link to="/web-checkout">
                Discover More <i class="fa-solid fa-arrow-right"></i>
              </Link>
            </div>

            {/* <div className="featurer ">
              <div className="img">
                <img src={f3} alt="featurer-3" />
              </div>
              <h3>Payouts</h3>
              <p className="grey">
                Copy paste a line of code and collect payments anytime,
                anywhere. No integration required.
              </p>

              <Link to="/payment-buttons">
                Discover More <i class="fa-solid fa-arrow-right"></i>
              </Link>
            </div> */}
            <div className="featurer ">
              <div className="img">
                <img src={f4} alt="featurer-4" />
              </div>
              <h3>InstaCollect</h3>
              <p className="grey">
                Collect payments instantly through virtual account
              </p>

              <Link to="/mobile-app-sdk">
                Discover More <i class="fa-solid fa-arrow-right"></i>
              </Link>
            </div>
            <div className="featurer ">
              <div className="img">
                <img src={f5} alt="featurer-5" />
              </div>
              <h3>Payment Links</h3>
              <p className="grey">
                No website? No problem! Share payment links and get paid in
                minutes. No coding required.
              </p>

              <Link to="/payment-links">
                Discover More <i class="fa-solid fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="finetech-wrapper finetech-wrapper-other center ">
          <div className="wrapper center gap-50">
            <div
              className="right center"
             
              style={{ maxWidth: "562px", marginRight:"auto" }}
            >
               <div>
               <h3 className="h3" style={{color:"#fff"}}>
              Best payment gateway built for India
              </h3>

              <h4 className="" style={{color:"#fff"}}>We are A Fintech Company!</h4>
              <p className=" mt-20" style={{ width: "402px",color:"#fff" }}>
              India’s leading brands have trusted Streamwellso payments platform to manage online payment collections, vendor payouts and financial operations. Sign up with us and experience the ease of managing payments and financial operations.
              </p>
               </div>
              {/* <div className="d-flex gap-50">
                <ul>
                  <li>Payment APIs</li>
                  <li>Platform Plugins</li>
                </ul>
                <ul>
                  <li>Extensive Libraries </li>
                  <li> Webhooks</li>
                </ul>
              </div>

              <div className="button">
                <Link to="/about" className="btn btn-1">
                  View More About Us!
                </Link>
              </div> */}
            </div>
            <div
              className="left"
            
            >
              {/* <img className="w-100" src={girl} alt="" /> */}
            </div>
          </div>
        </div>

        {/* <div className="wrapper featured-services-wrapper ecosystem-wrapper text-center ">
        
          <h3 className="h3">Harness our payment ecosystem to improve your cash flow and ease business operations. Get time to expand your business.</h3>
        <div className=" featured-services ecosystem d-flex justify-center gap-50">
          <div className="featurer ">
             <div className="img center">
                <div><i class="fa-solid fa-file-code"></i></div>
             </div>
             <div className="desc">
             <h3>Payment Gateway</h3>
             <p className="grey">
						        Offer your customers a secure payment gateway 150+ payment modes with the best success rates					
             </p>
             </div>
            
           
          </div>
          <div className="featurer ">
             <div className="img center">
             <div><i class="fa-solid fa-code"></i></div>
             </div>
             <div className="desc">
             <h3>No Code Payments</h3>
             <p className="grey">
             Share and accept payments via WhatsApp, SMS, Email. Add buttons to your website, and create invoices 
             </p>
             </div>
            

           
          </div>

          <div className="featurer ">
             <div className="img center">
             <div><i class="fa-solid fa-qrcode"></i></div>
             </div>
             <div className="desc">
             <h3>QR Code	</h3>
             <p className="grey">
             Make payments contactless with 140+ UPI apps. Make in-store payments & doorstep collections digital	
             </p>
             </div>
            
              
          </div>
        

        </div>
        </div> */}

        {/* <div className="wrapper industry-payment-wrapper text-center">
           <h3 className="h3" data-aos="fade-up"  data-aos-duration="1000" data-aos-delay="200" data-aos-once="true"
           >Tailored industry payment suites for the diverse online ecosystems</h3>
        
        <div className="industry-payment d-flex justify-center gap-50">
          <div className="industry-payment-card" data-aos="fade-right"  data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
            <div className="img">
              <img src={p1} alt="payment-img1" />
            </div>
            <p className="gray">
            Ecommerce stores and marketplaces Accept customer payments on the website, in-app or at the doorstep, process online and COD refunds, vendor payouts, and more.
            </p>
          </div>
          <div className="industry-payment-card" data-aos="fade-up"  data-aos-duration="1000" data-aos-delay="300" data-aos-once="true">
            <div className="img">
              <img src={p2} alt="payment-img2" />
            </div>
            <p className="gray">
            Lending and microfinance platforms Disburse instant loans, accept deposits and automate recurring loan repayment collection.
            </p>
          </div>
          <div className="industry-payment-card" data-aos="fade-left"  data-aos-duration="1000" data-aos-delay="400" data-aos-once="true">
            <div className="img">
              <img src={p3} alt="payment-img3" />
            </div>
            <p className="gray">
            Insurers, web aggregators, brokers and TPAs Sell policies, collect premiums on app or WhatsApp using payment links, verify policy holders’ account details, disburse claims, pay to agents and other intermediaries.
            </p>
          </div>
             
        </div>
        </div>
        <div>
        <div className="payAsYou-wrapper">
        <svg className="round-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
	<path class="elementor-shape-fill" d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
</svg>
            <div className="wrapper text-center">
              <h2>Pay As You Use</h2>
              <h3 className="h3">Transparent pricing that suits your business</h3>
              <p className="font-20">2% for Visa, Mastercard, Net Banking, BNPL, Wallets only domestic transactions
  
              </p>
              <p className="font-20">
              3% for Diners, American Express, EMI, International Transactions
              </p>

            </div>
            <svg className="round-top round-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
	<path class="elementor-shape-fill" d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
</svg>
        
        </div>
      
        </div>
    

        <div className="our-vission-wrapper">
          <div className="wrapper">
            <h3 className="h3">Our Vision</h3>
            <p>
            Grow your business with Instpaymax End to end payment, one platform -Unlimited solutions. A customized, fully integrated & payment solutions provider will help your business to grow by collecting payments from customers easily and securely through any device in any mode. Streamwellso enables businesses to collect online & offline payments across 150+ payment modes such as Credit Cards, Debit Cards, Net Banking, EMIs, BNPL, QR, UPI, Wallets and more.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
