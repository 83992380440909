import React, { useState } from 'react'
import "../PaymentGateways/PaymentGateways.css"
import paymentgateway from "../../assets/payment_gateway1.jpg"
import paymentgateway2 from "../../assets/paymetgateway2.jpg"
import paymentgateway3 from "../../assets/paymentgateway3.jpg";
import paymentgatewayanimation1 from "../../assets/payment-gateway-animation1.jpg"
import paymentgatewayanimation2 from "../../assets/payment-gateway-animation2.png"
import paymentgatewayanimation3 from "../../assets/payment-gateway-animation3.jpg"
import paymentgatewayanimation4 from "../../assets/payment-gateway-animation4.jpg"
import paymentgateway4 from "../../assets/paymentgateway4.jpg"
function PaymentGateways() {

  return (
    <div>
      <div className="main">
        <div className="wrapper">
         <div className='payment-gateway'>
            <div className='payment-gateway-left w-50'>
                <p> <h3 className='h2'>Building Better Payment Experiences with Streamwellso</h3>
                </p>
           <div style={{marginTop:"27px"}}>
           <p className='paragraph'>At Streamwellso, we are committed to transforming the way businesses and customers interact through payments. Our state-of-the-art payment gateway is designed to provide a seamless, secure, and efficient payment experience for all.</p>
           </div>           
            </div>
            <div className='payment-gateway-right w-50'>
            <img src={paymentgateway}></img>
            </div>           
         </div>
         <div className='payment-gateway' style={{marginTop:"30px"}}>
            <div className='payment-gateway-left w-50'>
            <img src={paymentgateway2}></img>
            </div>
            <div className='payment-gateway-right w-50'>
           
            <p> <h3 className='h2'>Accept Every Payment and Delight Every Customer with Streamwellso</h3></p>
           <div style={{marginTop:"27px"}}>
           <p className='payment-gateway-margin f-color' >At Streamwellso, our mission is to provide a comprehensive payment gateway that caters to the diverse needs of your customers. With Streamwellso, you can offer the widest range of payment methods, ensuring a seamless and delightful payment experience for all.</p>
                <div className='l-height f-color'><p>
                Delight your customers with over 150+ payment methods, including Credit Cards, Debit Cards, Net Banking, EMIs, BNPL (Buy Now, Pay Later), UPI, and Wallets. We ensure that every customer finds a convenient way to pay
                </p>
                </div>
           </div>           
            </div>          
         </div>
         <div className='payment-gateway-slider'>
            <div className='payment-gateway-slider-full  w-100 '>
              <div className='payment-gateway-image center'>
                <div>
              <h1 className='text-center'>Easy Integration With Streamwellso</h1>
                <p className='text-center f-23'>Power your business with our payment gateway <br/> APIs, super-light SDKs, plug and play<br/> partnerships. Try our interactive API playground <br/>for quick integration.</p>
                </div>
              </div>
               
            </div>
         </div>

         <div className='wrapper'>
          <div className='payment-gateway-animation'>
            <div className='payment-gateway-animation-head f-25'>
            <h2 className='text-center mb-20'>CHECKOUT</h2>
            </div>        
          </div>
         <div className='payment-gateway-animation'>
          <div className='payment-gateway-animation-main-left' 
         >
            <div className='payment-gateway-animation-main'>
              <div className='payment-gateway-animation-submain'>
                <img src={paymentgatewayanimation1} ></img>
                <div className='payment-gateway-animation-text f-25 fw-600'>
                  <h2>Web Checkout</h2>
                 
                  <h6>Integrate our payment gateway on your website for the best payment experience.</h6>
                  
                </div>

              </div>

            </div>
           
          </div>
          <div className='payment-gateway-animation-main-left '
         >
            <div className='payment-gateway-animation-main'>
              <div className='payment-gateway-animation-submain'>
                <img src={paymentgatewayanimation2} ></img>
                <div className='payment-gateway-animation-text f-25 fw-600'>
                  <h2>App Checkout</h2>
                  {
                  <h6>Offer best payment experience for your mobile app-based businesses using our feather-light SDKs.</h6>}
                </div>

              </div>

            </div>
          </div>
         </div>
         </div>
         {/* <div className='wrapper'>
          <div className='payment-gateway-animation'>
            <div className='payment-gateway-animation-head f-25'>
            <h2 className='text-center mb-20'>DEVELPOMENT</h2>
            </div>
           
          </div>
         <div className='payment-gateway-animation'>ser
          <div className='payment-gateway-animation-main-left'>
            <div className='payment-gateway-animation-main'>
              <div className='payment-gateway-animation-submain'>
                <img src={paymentgatewayanimation3} ></img>
                <div className='payment-gateway-animation-text f-25 fw-600'>
                  <h2>Developer Guide</h2>
                  
                
                  <h6>Access our ready-to-use payment gateway API documentation and production-ready designs.</h6>
                </div>

              </div>

            </div>
          </div>
          <div className='payment-gateway-animation-main-left'>
            <div className='payment-gateway-animation-main'>
              <div className='payment-gateway-animation-submain'>
                <img src={paymentgatewayanimation4} ></img>
                <div className='payment-gateway-animation-text f-25 fw-600'>
                  <h2>API Playground</h2>
                 
                  <h6>Check how your product integration is interacting with our payment APIs in real-time.</h6>
                </div>

              </div>

            </div>
          </div>
         </div>
         </div>
         <div className='wrapper'>
          <div className='payment-gateway-bottom'>
            <div className='payment-gateway-animation-head f-25'>
            <h2 className='text-center mb-20'>PLUG INS</h2>
            </div>
          </div>
         <div className='payment-gateway-bottom'>
         <div className='paymentgateway-bottom-data '>
          <img src={paymentgateway4}></img>
          </div>
          <div className='paymentgateway-bottom-data'>
          <h6 className='pd-10 f-color f-20'>Most popular plugins for all e-commerce businesses</h6>
          <p className='f-16 f-color pd-10 fw-500'>Use Streamwellso plugins to accept payments on popular self-hosted and open-source platforms like Magento, Shopify, Tally, ZohoBooks, ZohoCRM, WooCommerce, WordPress for creating a plugin for any e-commerce platform or shopping carts.</p>
          </div>
         </div>
         </div> */}
        </div>
   
      </div>
    </div>
  )
}

export default PaymentGateways
