import React, { useEffect } from 'react';
import './Contact.css';
import Banner from '../../Componants/Banner/Banner';
import banners from "../../assets/banner.png";

const Contact = ({setBanner}) => {

    const bannar = {
        banner: banners,
        h1: "Contact Us",
        button: "Let's Work Together!",
        buttonLink: "/contact",
        p: "You deserve to be seen. We are here to achieve your goal.",
        height:"auto"
      };

      useEffect(() =>{
        setBanner(bannar)
      },[])
  return (
   <>
  
  <div className="map-wrapper" style={{marginTop:"60px"}}>
        <div className="wrapper">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.031437698816!2d77.1304117746041!3d28.688706175634014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03146c57495b%3A0x2ed7cc8dc9fe5b40!2sAggarwal%20prestige%20Mall!5e0!3m2!1sen!2sin!4v1720785810302!5m2!1sen!2sin"
          width="100%" height="100" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    <div className='contact-wrapper'>
      <div className="wrapper d-flex justify-between  gap-50">
        <div className="contactLeft">
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-location-dot"></i>
                </div>
                <div className="text">
                    <h3>Address</h3>
                    <p>UNIT NO .- 106,FIRST FLOOR ,AGGARWAL PRESTIGE MALL PLOT NO .- 2, 
                    ROAD NO .-44,PITAMPURA.NEW DELHI - 110034</p>

                </div>

            </div>
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-envelope-circle-check"></i>
                </div>
                <div className="text">
                    <h3>Email</h3>
                    <p>info.streamwellsopvtltd@gmail.com</p>

                </div>

            </div>
            <div className="addressCard d-flex gap-20">
                <div className="icon self-center">
                <i class="fa-solid fa-phone"></i>
                </div>
                <div className="text">
                    <h3>Phone</h3>
                    <p>+91-8859389967</p>

                </div>

            </div>

        </div>
       
       
        {/* <div className="contactRight">
            <from>
                <div className="from-field">
                    <input type="text" placeholder='Your Name' />
                </div>
                <div className="from-field">
                    <input type="text" placeholder='Your Email' />
                </div>
                <div className="from-field">
                    <textarea name="" id="" placeholder='Your Message'  >
                    </textarea>
                </div>
                <div className="from-field">
                    <button type="submit" className='btn btn-2'>Submit Now!</button>
                </div>

            </from>
        </div> */}

      </div>
     
      </div>
   
   
   </>
  );
}

export default Contact;


