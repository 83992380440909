import "./App.css";
import { Routes, Route } from "react-router-dom";

import Header from "./Componants/Header/Header";
import Footer from "./Componants/Footer/Footer";
import Home from "./Pages/Home/Home";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/About/About";
import MobileAppSdk from "./Pages/MobileAppSdk/MobileAppSdk";
import WebCkeckout from "./Pages/WebCkeckout/WebCkeckout";
import PaymentGateways from "./Pages/PaymentGateways/PaymentGateways";
import Login from "./Pages/LoginRegister/Login";
import Register from "./Pages/LoginRegister/Register";
import ForgotPassword from "./Pages/LoginRegister/ForgotPassword";
import PaymentRecurring from "./Pages/PaymentRecurring/PaymentRecurring";
import InstantRefund from "./Pages/InstantRefund/InstantRefund";
import PrivacyPolicies from "./Pages/PrivacyPolicies/PrivacyPolicies";
import PaymentLink from "./Pages/PaymentLink/PaymentLink";
import PaymentButtons from "./Pages/PaymentButtons/PaymentButtons";
import CancellationPolicy from "./Pages/CancellationPolicy/CancellationPolicy";
import TermsandCondition from "./Pages/Terms&Condition/TermsandCondition";
import GrievanceRedressal from "./Pages/GrievanceRedressal/GrievanceRedressal";
import ScrollTop from "./Componants/ScrollTop/ScrollTop";
import Banner from "./Componants/Banner/Banner";
import homeBanner from "./assets/banner.png";
import { useState } from "react";

// const bannar = {
//   banner: homeBanner,
//   h1: "Make Payments Easier",
//   h2: "with Online Payments",
//   button: "Get Started!",
//   buttonLink: "/contact",
//   p: "Accept online payments and grow your business.",
//   height:""
// };




function App() {

  const [banner, setBanner] = useState({})

  return (
    <div className="App">
      
      <ScrollTop />
       <div className="main-wrapper">
       <Banner banner={banner} />
     
       <Routes>
        <Route path="/" element={<Home setBanner={setBanner}/>} />
        <Route path="/contact" element={<Contact setBanner={setBanner} />} />
        <Route path="/about" element={<About setBanner={setBanner} />} />
        <Route path="/mobile-app-sdk" element={<MobileAppSdk setBanner={setBanner} />} />
        <Route path="/web-checkout" element={<WebCkeckout setBanner={setBanner}/>} />
        {/* <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} /> */}
        <Route path="/payment-gateway" element={<PaymentGateways setBanner={setBanner} />} />
        <Route path="/forgotpassword" element={<ForgotPassword setBanner={setBanner} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicies setBanner={setBanner} />} />
        <Route path="/recurring-payments" element={<PaymentRecurring setBanner={setBanner} />} />
        <Route path="/instant-refund" element={<InstantRefund  setBanner={setBanner}/>} />
        <Route path="/payment-links" element={<PaymentLink setBanner={setBanner} />} />
        {/* <Route path="/payment-buttons" element={<PaymentButtons setBanner={setBanner} />} /> */}
        <Route path="/cancellation-policy" element={<CancellationPolicy setBanner={setBanner} />} />
        <Route path="/terms-of-use" element={<TermsandCondition setBanner={setBanner} />}  />
        <Route path="/grievance-redressal" element={<GrievanceRedressal setBanner={setBanner} />} />
      </Routes>
       </div>

      <Footer />
    </div>
  );
}

export default App;
